import { City, Country, EnerbitPagination, State } from "@enerbit/base";
import { FormTypeState } from "../models/states";

export class StringUtils {
  static capitalizeFirstLetter = (value: string): string => {
    if (value.length > 0) {
      return value[0].toUpperCase() + value.substring(1).toLowerCase();
    }
    return value;
  };

  static normalize = (value: string): string => {
    return value.normalize("NFD").replace(/\p{Diacritic}/gu, "");
  };

  static formatterUpdateState = (
    state: FormTypeState,
    geolocalization: {
      countries: EnerbitPagination<Country>;
      states: EnerbitPagination<State>;
      cities: EnerbitPagination<City>;
    }
  ) => {
    return {
      address: state.address,
      country: geolocalization.countries.items.find((value) => value.id == state.selectedCountryId)?.name ?? "",
      state: geolocalization.states.items.find((value) => value.id == state.selectedStateId)?.name ?? "",
      city: geolocalization.cities.items.find((value) => value.id == state.selectedCityId)?.name ?? "",
      dane_code: state.daneCode,
      geolocalization: {
        altitude: state.altitude,
        longitude: state.longitude,
        latitude: state.latitude,
        plus_code: state.plusCode,
      },
    };
  };
}
