import {
  ApartmentOutlinedIcon,
  SectionCard,
  Typography,
  enerbitColors,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import { StateStorage } from "../../models/states";

const SectionCards = () => {
  const { initialState } = useSelector((state: StateStorage) => state.state);
  const color = enerbitColors.primary.main;
  return (
    <>
      <SectionCard
        color={"white"}
        body={
          <>
            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              Dirección{" "}
              <span
                style={{ color: enerbitColors.neutral[700], fontWeight: "700" }}
              >
                {initialState?.address}
              </span>
            </Typography>

            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              Ciudad{" "}
              <span
                style={{ color: enerbitColors.neutral[700], fontWeight: "700" }}
              >
                {initialState?.city}
              </span>
            </Typography>

            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              Departamento{" "}
              <span
                style={{ color: enerbitColors.neutral[700], fontWeight: "700" }}
              >
                {initialState?.state}
              </span>
            </Typography>

            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              Latitud{" "}
              <span
                style={{ color: enerbitColors.neutral[700], fontWeight: "700" }}
              >
                {initialState?.geolocalization.latitude}
              </span>
            </Typography>

            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              Longitud{" "}
              <span
                style={{ color: enerbitColors.neutral[700], fontWeight: "700" }}
              >
                {initialState?.geolocalization.longitude}
              </span>
            </Typography>

            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              Altitud{" "}
              <span
                style={{ color: enerbitColors.neutral[700], fontWeight: "700" }}
              >
                {initialState?.geolocalization.altitude}
              </span>
            </Typography>

            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              Estate ID{" "}
              <span
                style={{ color: enerbitColors.neutral[700], fontWeight: "700" }}
              >
                {initialState?.id}
              </span>
            </Typography>

            <Typography sx={{ color: enerbitColors.neutral.main, mt: "10px" }}>
              Plus code{" "}
              <span
                style={{ color: enerbitColors.neutral[700], fontWeight: "700" }}
              >
                {initialState?.geolocalization.plus_code}
              </span>
            </Typography>
          </>
        }
        title={
          <Typography sx={{ display: "flex" }}>
            <ApartmentOutlinedIcon sx={{ color: color, fontSize: "17px" }} />{" "}
            <span
              style={{ color: color, fontWeight: "bold", marginLeft: "7px" }}
            >
              {" "}
              Información del predio
            </span>
          </Typography>
        }
      />
    </>
  );
};

export default SectionCards;
