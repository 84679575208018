import { CatrastalType, Country, EnerbitPagination, SocialStratum, State, api, geopoliticsApi } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getCountries = createAsyncThunk(
  //action type string
  "[GEOPOLITICS] Get countries",
  //callback function
  async (_payload, _thunkAPI): Promise<EnerbitPagination<Country>> => {
    const { data, status } = await geopoliticsApi.get(`countries/`, {
      params: {
        size: 300,
      },
    });
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);
export const getStates = createAsyncThunk(
  //action type string
  "[GEOPOLITICS] Get States",
  //callback function
  async (payload: string, _thunkAPI): Promise<EnerbitPagination<State>> => {
    const { data, status } = await geopoliticsApi.get(`countries/${payload}/states`, {
      params: {
        size: 300,
      },
    });
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getCities = createAsyncThunk(
  //action type string
  "[GEOPOLITICS] Get Cities",
  //callback function
  async (payload: string, _thunkAPI): Promise<EnerbitPagination<State>> => {
    const { data, status } = await geopoliticsApi.get(`states/${payload}/cities`, {
      params: {
        size: 300,
      },
    });
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);
export const getSocialStratums = createAsyncThunk(
  //action type string
  "[ACCOUNTS] Get SUI Social Stratums",
  //callback function
  async (_payload, _thunkAPI): Promise<EnerbitPagination<SocialStratum>> => {
    const { data, status } = await api.get(`accounts/social-stratums/`);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);
export const getCatastralTypes = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Get Catastral Types",
  //callback function
  async (_payload, _thunkAPI): Promise<CatrastalType[]> => {
    const { data, status } = await api.get(`electricity-supply-service/manager/catastral-types/`);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);
