import {
  CircularProgress,
  CustomAlert,
  EditOutlinedIcon,
  FormGroup,
  FormikType,
  Grid,
  InputLabel,
  LoadingButton,
  MenuItem,
  TextField,
  Typography,
  useFormik,
} from "@enerbit/base";

import { yup } from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getCatastralTypes,
  getCities,
  getCountries,
  getSocialStratums,
  getStates,
} from "../../actions/geopolitics/geopolitics.actions";
import { updateState } from "../../actions/states/states.actions";
import { StringUtils } from "../../common/Utils";
import { setCleanVars } from "../../features/states/statesSlice";
import { FormTypeState, StateStorage } from "../../models/states";
import { AppDispatch } from "../../store/store";

export const validationSchema = yup.object().shape({
  selectedCountryId: yup.string().required("El país es requerido"),
  selectedStateId: yup.string().required("El departamento es requerido"),
  selectedCityId: yup.string().required("La ciudad es requerido"),
  address: yup.string().required("La dirección es requerido"),
  socialStratumId: yup.string(),
  catastralTypeId: yup.string(),
  catastralIdentifier: yup.string(),
  longitude: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("La longitud es requerida"),
  latitude: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("La latitud es requerida"),
  altitude: yup
    .number()
    .typeError("El campo debe de ser númerico")
    .required("La altitud es requerida"),
  plusCode: yup.string().required("El campo es requerido"),
});

export const handleTextFieldChanged = (
  value: any,
  field: string,
  formik: FormikType<any>
) => {
  formik.setFieldTouched(field);
  formik.setFieldValue(field, value);
};

export const Information = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    paginationDataCountries,
    paginationDataStates,
    paginationDataCities,
    isLoadingCountries,
    isLoadingStates,
    isLoadingCities,
    paginationSocialStratum,
    isLoadingSocialStratums,
    catastralTypes,
    isLoadingCatastralTypes,
    initialState,
    wasUpdateState,
    isUpdateState,
  } = useSelector((state: StateStorage) => state.state);

  const { id } = useParams();

  const formik: FormikType<FormTypeState> = useFormik<FormTypeState>({
    initialValues: {
      selectedCountryId: "",
      selectedStateId: "",
      selectedCityId: "",
      address: "",
      socialStratumId: "",
      catastralTypeId: "",
      catastralIdentifier: "",
      longitude: 0,
      latitude: 0,
      altitude: 0,
      plusCode: "",
      daneCode: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      dispatch(updateState({ id: id ?? "", state: values }));
    },
  });

  // Countries
  useEffect(() => {
    if (initialState != null) {
      dispatch(getCountries());
      dispatch(getCatastralTypes());
      dispatch(getSocialStratums());

      handleTextFieldChanged(initialState.address, "address", formik);
      handleTextFieldChanged(
        initialState.geolocalization.altitude,
        "altitude",
        formik
      );
      handleTextFieldChanged(
        initialState.geolocalization.latitude,
        "latitude",
        formik
      );
      handleTextFieldChanged(
        initialState.geolocalization.longitude,
        "longitude",
        formik
      );
      handleTextFieldChanged(
        initialState.geolocalization.plus_code,
        "plusCode",
        formik
      );
      handleTextFieldChanged(initialState.dane_code, "daneCode", formik);
      handleTextFieldChanged(
        initialState.details?.catastral_id,
        "catastralIdentifier",
        formik
      );
    }
  }, [initialState]);

  useEffect(() => {
    if (
      paginationDataCountries &&
      paginationDataCountries.items.length > 0 &&
      initialState
    ) {
      const country = paginationDataCountries.items.find(
        (values) =>
          values.name.toLocaleLowerCase() ==
          initialState?.country.toLocaleLowerCase()
      );
      handleTextFieldChanged(country?.id, "selectedCountryId", formik);
    }
  }, [initialState, paginationDataCountries]);

  // States
  useEffect(() => {
    if (
      formik.values.selectedCountryId &&
      formik.values.selectedCountryId != ""
    ) {
      dispatch(getStates(formik.values.selectedCountryId));
    }
  }, [formik.values.selectedCountryId, initialState]);

  useEffect(() => {
    if (
      paginationDataStates &&
      paginationDataStates.items.length > 0 &&
      initialState
    ) {
      const state = paginationDataStates.items.find(
        (state) => initialState?.state.toLowerCase() == state.name.toLowerCase()
      );
      if (state) {
        handleTextFieldChanged(state.id, "selectedStateId", formik);
      }
    }
  }, [initialState, paginationDataStates]);

  // Cities
  useEffect(() => {
    if (formik.values.selectedStateId && formik.values.selectedStateId != "") {
      dispatch(getCities(formik.values.selectedStateId));
    }
  }, [formik.values.selectedStateId, initialState]);

  useEffect(() => {
    if (
      paginationDataCities &&
      paginationDataCities.items.length > 0 &&
      initialState
    ) {
      const city = paginationDataCities.items.find(
        (city) =>
          StringUtils.normalize(city.name.toLowerCase()) ==
          StringUtils.normalize(initialState.city.toLowerCase())
      );
      if (city) {
        handleTextFieldChanged(city.id, "selectedCityId", formik);
      }
    }
  }, [initialState, paginationDataCities]);

  useEffect(() => {
    if (initialState?.details && paginationSocialStratum?.items?.length) {
      const socialStratum = paginationSocialStratum.items.find(
        (socialStratum) =>
          socialStratum.code === initialState.details?.social_stratum?.code
      );
      if (socialStratum) {
        handleTextFieldChanged(socialStratum.id, "socialStratumId", formik);
      }
    }
  }, [paginationSocialStratum, initialState]);

  useEffect(() => {
    console.log("initialState:", initialState);
    console.log("catastralTypes:", catastralTypes);

    if (initialState?.details && catastralTypes?.length > 0) {
      console.log(
        "initialState.details.catastral_type:",
        initialState.details.catastral_type
      );
      const catastralType = catastralTypes.find(
        (catastralType) =>
          catastralType.id === initialState.details?.catastral_type?.id
      );
      console.log("catastralType:", catastralType);

      if (catastralType && catastralType.id) {
        handleTextFieldChanged(catastralType.id, "catastralTypeId", formik);
      }
    }
  }, [catastralTypes, initialState]);
  console.log(
    formik?.values?.catastralTypeId,
    "formik?.values?.catastralTypeId"
  );
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={6}>
            <Typography
              color="primary"
              sx={{
                fontWeight: "400",
                fontSize: "28px",
              }}
            >
              Información
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              loading={isUpdateState}
              sx={{ width: "90%" }}
              variant="contained"
              color="primary"
              type="submit"
            >
              <Typography className="button-content">
                <EditOutlinedIcon sx={{ fontSize: "17px", mr: "0.3rem" }} />
                Editar información
              </Typography>
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item md={12} xs={12}>
            {wasUpdateState != null && (
              <CustomAlert
                severity={wasUpdateState ? "success" : "error"}
                text={
                  wasUpdateState
                    ? "Servicio actualizado exitosamente"
                    : "Ocurrió un error actualizando el servicio"
                }
                onClose={() => dispatch(setCleanVars())}
                sx={{ mb: "24px" }}
              />
            )}
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <InputLabel shrink className="Input-label">
                País
              </InputLabel>
              <TextField
                fullWidth
                select
                className="TextField-without-border-radius"
                id="selectedCountryId"
                name="selectedCountryId"
                variant="outlined"
                value={formik.values.selectedCountryId}
                helperText={
                  formik.touched.selectedCountryId &&
                  formik.errors.selectedCountryId
                }
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  e.preventDefault();
                  handleTextFieldChanged(
                    e.target.value,
                    "selectedCountryId",
                    formik
                  );
                }}
                error={
                  formik.touched.selectedCountryId &&
                  Boolean(formik.errors.selectedCountryId)
                }
              >
                {isLoadingCountries ? (
                  <CircularProgress />
                ) : (
                  paginationDataCountries?.items.map((country) => {
                    return (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    );
                  }) ?? (
                    <Typography variant="body1">
                      No hemos encontrado ningún resultado
                    </Typography>
                  )
                )}
              </TextField>
            </FormGroup>
          </Grid>

          {formik.values.selectedCountryId != "" &&
            formik.values.selectedCountryId && (
              <Grid item md={4} xs={12}>
                <FormGroup>
                  <InputLabel shrink className="Input-label">
                    Departamento
                  </InputLabel>
                  <TextField
                    fullWidth
                    select
                    className="TextField-without-border-radius"
                    id="selectedStateId"
                    name="selectedStateId"
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.selectedStateId}
                    error={
                      formik.touched.selectedStateId &&
                      Boolean(formik.errors.selectedStateId)
                    }
                    helperText={
                      formik.touched.selectedStateId &&
                      formik.errors.selectedStateId
                    }
                  >
                    {isLoadingStates ? (
                      <CircularProgress />
                    ) : (
                      paginationDataStates?.items.map((state) => {
                        return (
                          <MenuItem key={state.id} value={state.id}>
                            {state.name}
                          </MenuItem>
                        );
                      }) ?? (
                        <Typography variant="body1">
                          No hemos encontrado ningún resultado
                        </Typography>
                      )
                    )}
                  </TextField>
                </FormGroup>
              </Grid>
            )}

          {formik.values.selectedStateId != "" &&
            formik.values.selectedStateId && (
              <Grid item md={4} xs={12}>
                <FormGroup>
                  <InputLabel shrink className="Input-label">
                    Ciudad
                  </InputLabel>
                  <TextField
                    fullWidth
                    select
                    className="TextField-without-border-radius"
                    id="selectedCityId"
                    name="selectedCityId"
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.selectedCityId}
                    error={
                      formik.touched.selectedCityId &&
                      Boolean(formik.errors.selectedCityId)
                    }
                    helperText={
                      formik.touched.selectedCityId &&
                      formik.errors.selectedCityId
                    }
                  >
                    {isLoadingCities ? (
                      <CircularProgress />
                    ) : (
                      paginationDataCities?.items.map((city) => {
                        return (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        );
                      }) ?? (
                        <Typography variant="body1">
                          No hemos encontrado ningún resultado
                        </Typography>
                      )
                    )}
                  </TextField>
                </FormGroup>
              </Grid>
            )}
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item md={initialState?.details ? 6 : 12} xs={12}>
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Dirección
              </InputLabel>
              <TextField
                fullWidth
                className="TextField-without-border-radius"
                id="address"
                name="address"
                variant="outlined"
                value={formik.values.address}
                helperText={formik.touched.address && formik.errors.address}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
              />
            </FormGroup>
          </Grid>
          {initialState?.details && (
            <>
              <Grid item md={6} xs={12}>
                <FormGroup>
                  <InputLabel shrink className="Input-label">
                    Estrato
                  </InputLabel>
                  <TextField
                    disabled
                    fullWidth
                    select
                    className="TextField-without-border-radius"
                    id="socialStratumId"
                    name="socialStratumId"
                    variant="outlined"
                    value={formik.values.socialStratumId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.socialStratumId &&
                      Boolean(formik.errors.socialStratumId)
                    }
                    helperText={
                      formik.touched.socialStratumId &&
                      formik.errors.socialStratumId
                    }
                  >
                    {isLoadingSocialStratums ? (
                      <CircularProgress />
                    ) : // Verificación adicional para asegurar que paginationSocialStratum?.items no sea undefined
                    (paginationSocialStratum?.items?.length ?? 0) > 0 ? (
                      paginationSocialStratum?.items.map((socialStratum) => (
                        <MenuItem
                          key={socialStratum.id}
                          value={socialStratum.id}
                        >
                          {`${socialStratum.description} - ${socialStratum.code}`}
                        </MenuItem>
                      ))
                    ) : (
                      <Typography variant="body1">
                        No hemos encontrado ningún resultado
                      </Typography>
                    )}
                  </TextField>
                </FormGroup>
              </Grid>
              {catastralTypes && catastralTypes.length > 0 && (
                <Grid item md={6} xs={12}>
                  <FormGroup>
                    <InputLabel shrink className="Input-label">
                      Tipo de identificador catastral
                    </InputLabel>
                    <TextField
                      disabled
                      fullWidth
                      select
                      className="TextField-without-border-radius"
                      id="catastralTypeId"
                      name="catastralTypeId"
                      variant="outlined"
                      value={formik.values.catastralTypeId}
                      onChange={(e) => {
                        e.preventDefault();
                        handleTextFieldChanged(
                          e.target.value,
                          "catastralTypeId",
                          formik
                        );
                      }}
                      error={
                        formik.touched.catastralTypeId &&
                        Boolean(formik.errors.catastralTypeId)
                      }
                      helperText={
                        formik.touched.catastralTypeId &&
                        formik.errors.catastralTypeId
                      }
                    >
                      {" "}
                      {isLoadingCatastralTypes ? (
                        <CircularProgress />
                      ) : catastralTypes && catastralTypes.length > 0 ? (
                        catastralTypes.map((catastralType) =>
                          catastralType &&
                          catastralType.id &&
                          catastralType.description ? (
                            <MenuItem
                              key={catastralType.id}
                              value={catastralType.id}
                            >
                              {catastralType.description}
                            </MenuItem>
                          ) : null
                        )
                      ) : (
                        <Typography variant="body1">
                          No hemos encontrado ningún resultado
                        </Typography>
                      )}
                    </TextField>
                  </FormGroup>
                </Grid>
              )}
              <Grid item md={6} xs={12}>
                <FormGroup>
                  <InputLabel shrink className="Input-label">
                    Identificador catastral
                  </InputLabel>
                  <TextField
                    disabled
                    fullWidth
                    className="TextField-without-border-radius"
                    id="catastralIdentifier"
                    name="catastralIdentifier"
                    variant="outlined"
                    value={formik.values.catastralIdentifier}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.catastralIdentifier &&
                      Boolean(formik.errors.catastralIdentifier)
                    }
                    helperText={
                      formik.touched.catastralIdentifier &&
                      formik.errors.catastralIdentifier
                    }
                  />
                </FormGroup>
              </Grid>
            </>
          )}
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Longitud
              </InputLabel>
              <TextField
                fullWidth
                className="TextField-without-border-radius"
                id="longitude"
                name="longitude"
                variant="outlined"
                value={formik.values.longitude}
                helperText={formik.touched.longitude && formik.errors.longitude}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.longitude && Boolean(formik.errors.longitude)
                }
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Latitud
              </InputLabel>
              <TextField
                fullWidth
                className="TextField-without-border-radius"
                id="latitude"
                name="latitude"
                variant="outlined"
                value={formik.values.latitude}
                helperText={formik.touched.latitude && formik.errors.latitude}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.latitude && Boolean(formik.errors.latitude)
                }
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <InputLabel shrink className="Input-label">
                Altitud
              </InputLabel>
              <TextField
                fullWidth
                className="TextField-without-border-radius"
                id="altitude"
                name="altitude"
                variant="outlined"
                value={formik.values.altitude}
                helperText={formik.touched.altitude && formik.errors.altitude}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.altitude && Boolean(formik.errors.altitude)
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: "20px" }}>
          <Grid item md={6} xs={12}>
            <FormGroup sx={{ marginTop: "36px" }}>
              <InputLabel shrink className="Input-label">
                Código dane
              </InputLabel>
              <TextField
                fullWidth
                className="TextField-without-border-radius"
                id="daneCode"
                name="daneCode"
                variant="outlined"
                value={formik.values.daneCode}
                helperText={formik.touched.daneCode && formik.errors.daneCode}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  e.preventDefault();
                  handleTextFieldChanged(e.target.value, "daneCode", formik);
                }}
                error={
                  formik.touched.daneCode && Boolean(formik.errors.daneCode)
                }
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup sx={{ marginTop: "36px" }}>
              <InputLabel shrink className="Input-label">
                Plus code
              </InputLabel>
              <TextField
                fullWidth
                className="TextField-without-border-radius"
                id="plusCode"
                name="plusCode"
                variant="outlined"
                value={formik.values.plusCode}
                helperText={formik.touched.plusCode && formik.errors.plusCode}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.plusCode && Boolean(formik.errors.plusCode)
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
export default Information;
