import {
  ArrowCircleLeftOutlinedIcon,
  Box,
  Button,
  IconButton,
  styled,
  theme,
  Typography,
} from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIndexTab } from "../../features/states/statesSlice";
import { StateStorage } from "../../models/states";
import { AppDispatch } from "../../store/store";
import SectionCards from "../cards/SectionCards";

const LeftMenu = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { indexTab } = useSelector((state: StateStorage) => state.state);
  const navigate = useNavigate();
  return (
    <Box bgcolor={"#F2F4F7"} sx={{ height: "100vw" }}>
      <Box sx={{ pt: "36px", pl: "36px", pr: "20px", width: "354px" }}>
        <Box>
          <IconButton sx={{ p: "0", mb: "20px" }} onClick={() => navigate(-1)}>
            <ArrowCircleLeftOutlinedIcon
              htmlColor={theme.palette.primary.main}
              fontSize="large"
            />
          </IconButton>
          <SectionCards />
          <Button
            size="small"
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ mt: "20px" }}
            onClick={() => {
              dispatch(setIndexTab(1));
              console.log("indexTab", indexTab);
            }}
          >
            Editar y ver más Información
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftMenu;

const TypographyStyled = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  color: ${(props: any) => props.theme.palette.primary.main};
`;

const Span = styled("span")`
  font-weight: 400;
`;
