import {
  ArrowForwardIcon,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  translateServiceName,
} from "@enerbit/base";
import { useSelector } from "react-redux";
import * as singleSpa from "single-spa";
import { StateStorage } from "../../models/states";

export const ServiceAssociated = () => {
  const { serviceCards, isGetServiceCards } = useSelector(
    (state: StateStorage) => state.state
  );

  if (isGetServiceCards) {
    return (
      <Box className="Loading-states-inf">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table className="Table-states" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tipo de servicio</TableCell>
            <TableCell>ID del servicio</TableCell>
            <TableCell align="right">Ver Detalle</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceCards.map((value, index) => {
            return (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">
                  {translateServiceName(value.service_type_name)}
                </TableCell>
                <TableCell scope="row">{value.service_id}</TableCell>
                <TableCell align="right">
                  <Button
                    sx={{ minWidth: "32px" }}
                    variant="contained"
                    color="secondary"
                    disabled={value.service_type_name !== "electricity-supply"}
                    size="small"
                    className="Loading-button Button-xs"
                    onClick={() =>
                      singleSpa.navigateToUrl(
                        `/#/electricity-supply-service/attention/${value.service_id}`
                      )
                    }
                  >
                    <ArrowForwardIcon
                      sx={{ fontSize: "16px", color: "white" }}
                    />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          {serviceCards.length == 0 && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell colSpan={3} scope="row">
                No se encontraron registros
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
