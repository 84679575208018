import { NavSidebar, PrivateRoute, ThemeConfig, initEnviroment } from "@enerbit/base";
import { Provider } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./assets/css/login.scss";
import States from "./pages/States";
import { store } from "./store/store";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL,
  geopoliticsBaseUrl: process.env.REACT_APP_GEOPOLITICS_BASE_URL,
});

export default function Root() {
  return (
    <Provider store={store}>
      <PrivateRoute>
        <ThemeConfig>
          <NavSidebar />
          <section className="Container-root">
            <HashRouter>
              <Routes>
                <Route path="states/:id" element={<States />} />
              </Routes>
            </HashRouter>
          </section>
        </ThemeConfig>
      </PrivateRoute>
    </Provider>
  );
}
