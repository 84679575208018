import { PayloadAction, createSlice } from "@reduxjs/toolkit";
//@ts-ignore
import { CatrastalType, City, Country, EnerbitPagination, SocialStratum } from "@enerbit/base";
import {
  getCatastralTypes,
  getCities,
  getCountries,
  getSocialStratums,
  getStates,
} from "../../actions/geopolitics/geopolitics.actions";
import { getInitalState, getServiceCards, updateState } from "../../actions/states/states.actions";
import { InitialState, State, StateCards, States } from "../../models/states";

const initialState: State = {
  indexTab: 0,
  isLoadingCountries: false,
  isLoadingStates: false,
  isLoadingCities: false,
  isLoadingSuiSocialStratums: false,
  isLoadingSocialStratums: false,
  suiSocialStratum: [],
  catastralTypes: [],
  isLoadingCatastralTypes: false,
  isLoadingState: true,
  initialState: null,
  isUpdateState: false,
  wasUpdateState: null,
  isGetServiceCards: false,
  serviceCards: [],
};

export const stateSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIndexTab: (state, action: PayloadAction<number>) => {
      state.indexTab = action.payload;
    },
    setCleanVars: (state) => {
      state.wasUpdateState = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending.type, (state, _action) => {
        state.isLoadingCountries = true;
        state.paginationDataCountries = undefined;
        state.paginationDataStates = undefined;
        state.paginationDataCities = undefined;
      })
      .addCase(getCountries.fulfilled.type, (state, action: PayloadAction<EnerbitPagination<Country>>) => {
        state.isLoadingCountries = false;
        state.paginationDataCountries = action.payload;
      })
      .addCase(getCountries.rejected.type, (state, _payload) => {
        state.isLoadingCountries = false;
      })
      // getStates
      .addCase(getStates.pending.type, (state, _action) => {
        state.isLoadingStates = true;
        state.paginationDataStates = undefined;
        state.paginationDataCities = undefined;
      })
      .addCase(getStates.fulfilled.type, (state, action: PayloadAction<EnerbitPagination<States>>) => {
        state.isLoadingStates = false;
        state.paginationDataStates = action.payload;
      })
      .addCase(getStates.rejected.type, (state, _action) => {
        state.isLoadingStates = false;
      })
      // getCities
      .addCase(getCities.pending.type, (state, _action) => {
        state.isLoadingCities = true;
        state.paginationDataCities = undefined;
      })
      .addCase(getCities.fulfilled.type, (state, action: PayloadAction<EnerbitPagination<City>>) => {
        state.isLoadingCities = false;
        state.paginationDataCities = action.payload;
      })
      .addCase(getCities.rejected.type, (state, _action) => {
        state.isLoadingCities = false;
      })
      // getSocialStratums
      .addCase(getSocialStratums.pending.type, (state, _action) => {
        state.isLoadingSocialStratums = true;
        state.paginationSocialStratum = undefined;
      })
      .addCase(getSocialStratums.fulfilled.type, (state, action: PayloadAction<EnerbitPagination<SocialStratum>>) => {
        state.isLoadingSocialStratums = false;
        const items = action.payload.items.sort((a, b) => a.code.localeCompare(b.code, "en", { numeric: true }));
        state.paginationSocialStratum = { ...action.payload, items: items };
      })
      .addCase(getSocialStratums.rejected.type, (state, _action) => {
        state.isLoadingSocialStratums = false;
      })
      // getCatastralTypes
      .addCase(getCatastralTypes.pending.type, (state, _action) => {
        state.isLoadingCatastralTypes = true;
        state.catastralTypes = [];
      })
      .addCase(getCatastralTypes.fulfilled.type, (state, action: PayloadAction<CatrastalType[]>) => {
        state.isLoadingCatastralTypes = false;
        state.catastralTypes = action.payload;
      })
      .addCase(getCatastralTypes.rejected.type, (state, _action) => {
        state.isLoadingCatastralTypes = false;
      })
      // getStates
      .addCase(getInitalState.pending.type, (state, _action) => {
        state.isLoadingState = true;
        state.initialState = null;
      })
      .addCase(getInitalState.fulfilled.type, (state, action: PayloadAction<InitialState>) => {
        state.isLoadingState = false;
        state.initialState = action.payload;
      })
      .addCase(getInitalState.rejected.type, (state, _action) => {
        state.isLoadingState = false;
      })
      // updateCregSuscribers
      .addCase(updateState.pending.type, (state, _action) => {
        state.isUpdateState = true;
        state.wasUpdateState = null;
      })
      .addCase(updateState.fulfilled.type, (state, action: PayloadAction<InitialState>) => {
        state.initialState = action.payload;
        state.isUpdateState = false;
        state.wasUpdateState = true;
      })
      //getCards
      .addCase(getServiceCards.pending.type, (state, _action) => {
        state.isGetServiceCards = true;
      })
      .addCase(getServiceCards.fulfilled.type, (state, action: PayloadAction<EnerbitPagination<StateCards>>) => {
        state.isGetServiceCards = false;
        state.serviceCards = action.payload.items;
      })
      .addCase(getServiceCards.rejected.type, (state, _action) => {
        state.isGetServiceCards = false;
      });
  },
});

export const { setIndexTab, setCleanVars } = stateSlice.actions;

export default stateSlice.reducer;
