import { EnerbitPagination, api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { StringUtils } from "../../common/Utils";
import { StateCards, StateStorage, States, UpdateState } from "../../models/states";

export const getInitalState = createAsyncThunk(
  //action type string
  "[ACCOUNTS] Get state",
  //callback function
  async (id: string, _thunkAPI): Promise<States> => {
    const { data, status } = await api.get(`accounts/estates/${id}/`);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getServiceCards = createAsyncThunk(
  //action type string
  "[ACCOUNTS] Get service cards",
  //callback function
  async (id: string, _thunkAPI): Promise<EnerbitPagination<StateCards>> => {
    const { data, status } = await api.get(`accounts/estate-relationships?estate_id=${id}`);
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export const updateState = createAsyncThunk(
  //action type string
  "[ELECTRICITY SUPPLY SERVICES] Update estates",
  //callback function
  async (payload: UpdateState, thunkAPI): Promise<any> => {
    const state = thunkAPI.getState() as StateStorage;
    const { data, status } = await api.patch(
      `accounts/estates/${payload.id}/`,
      StringUtils.formatterUpdateState(payload.state, {
        cities: state.state.paginationDataCities!,
        countries: state.state.paginationDataCountries!,
        states: state.state.paginationDataStates!,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (status === 200) {
      return data;
    }
    throw new Error("Data not found");
  }
);
