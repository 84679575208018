import { TabModel } from "../../models/states";
import MuiTabs from "./MuiTabs";

export interface ListTabsProps {
  tabs: TabModel[];
}

const ListTabs = ({ tabs }: ListTabsProps) => {
  return <MuiTabs tabs={tabs} />;
};

export default ListTabs;
