import { Box, Tab, Tabs } from "@enerbit/base";
import { SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIndexTab } from "../../features/states/statesSlice";
import { StateStorage, TabModel, TabPanelProps } from "../../models/states";
import { AppDispatch } from "../../store/store";

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export type MuiTabsProps = {
  tabs: TabModel[];
  disabled?: boolean;
};

export default function MuiTabs({ tabs, disabled }: MuiTabsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { indexTab } = useSelector((state: StateStorage) => state.state);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    dispatch(setIndexTab(newValue));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: "20px" }}>
        <Tabs
          value={indexTab}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: { backgroundColor: "orange", color: "black" },
          }}
        >
          {tabs.map(({ label, disabled }, i) => (
            <Tab disabled={disabled} className="Custom-tabs" label={label} key={i} />
          ))}
        </Tabs>
      </Box>
      {tabs.map(({ component }, i) => (
        <TabPanel value={indexTab} index={i} key={i}>
          {component}
        </TabPanel>
      ))}
    </Box>
  );
}
