import { configureStore } from "@reduxjs/toolkit";
import statesReducer from "../features/states/statesSlice";

export const store = configureStore({
  reducer: {
    state: statesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
