import { Box, CircularProgress, styled } from "@enerbit/base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getInitalState, getServiceCards } from "../actions/states/states.actions";
import { StateStorage, TabModel } from "../models/states";
import { AppDispatch } from "../store/store";
import { Information } from "./components-tabs/Information";
import { ServiceAssociated } from "./components-tabs/ServiceAssociated";
import LeftMenu from "./left-menu/LeftMenu";
import ListTabs from "./tabs/ListTabs";

const States = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const tabs: TabModel[] = [
    {
      label: "Servicios asociados",
      component: <ServiceAssociated />,
    },
    {
      label: "Información",
      component: <Information />,
    },
  ];

  useEffect(() => {
    dispatch(getInitalState(id ?? ""));
    dispatch(getServiceCards(id ?? ""));
  }, []);

  const { isLoadingState } = useSelector((state: StateStorage) => state.state);

  return (
    <StatesPageContainer>
      {isLoadingState ? (
        <Box className="Loading-states-inf">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <LeftMenu />
          <ContentContainer>
            <ListTabs tabs={tabs} />
          </ContentContainer>
        </>
      )}
    </StatesPageContainer>
  );
};

export default States;

const StatesPageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media (min-width: 768px) {
    flex-direction: row;
  }
  height: 100%;
`;

const ContentContainer = styled("div")`
  padding: 24px 36px 36px 36px;
  width: 100%;
`;
